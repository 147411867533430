.l-present {
  border-top: 5px solid $hama_blue_2;
  padding: 60px 0 0 0;
  &__btn-row {
    gap: 0 40px;
  }
  &__content {
    background-color: $hama_white_3;
    padding: 10px;
    &__head {
      font-size: 19px;
      line-height: 23px;
      text-align: center;
      word-break: break-all;
      word-wrap: anywhere;
      font-weight: 600;
      color:$hama_white_1;
      background-color: $hama_blue_1;
      padding: 8.5px 0;
      @media (min-width: 581px) {
        font-size: 28px;
        line-height: 32px;
        padding: 12px 0;
      }
    }
    &__text {
      padding: 10px;
      word-break: break-all;
      word-wrap: anywhere;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      background-color: $hama_yellow_2;
      color: $hama_red_1;
      @media (min-width: 581px) {
        padding: 20px;
        font-size: 26px;
        line-height: 32px;
      }
    }
    &__date {
      display: block;
      padding: 8px;
      color: $hama_black_1;
      background-color: $hama_white_1;
      word-break: break-all;
      word-wrap: anywhere;
      font-size: 13px;
      line-height: 20px;
      text-align: left;
      @media (min-width: 581px) {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
      }
      > span {
        display: block;
        @media (min-width: 581px) {
          display: inline-block;
        }
        >.is-blue-1 {
          color: $hama_blue_1;
        }
        >.is-blue-2 {
          color: $hama_blue_2;
        }
      }

    }
    &__btn-wrap {
      padding: 20px 0 10px 0;
    }
  }
  &__postcard-detail{
    padding: 60px 0 120px 0;
    background-color: $hama_white_3;
    &__img {
      width: 100%;
      margin-bottom: 30px;
      @media (min-width: 581px) {
        max-width: 660px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
  }
}