html {
  //font-size: 62.5%;
  font-size: 16px;
  scroll-behavior: smooth;
}
:target {
  // アンカーリンク用、ヘッダーの高さ設定
  scroll-margin-top: 65px;
  @media (min-width: 581px) {
    scroll-margin-top: calc(88px + 70px);
  }
}
body {
  font-family: Roboto, "Noto Sans JP", Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Droid Sans", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  color: $hama_black_1;
  min-width: 375px;
  width: 100%;
  @media (min-width: 581px) {
    min-width: 1240px;
  }

  &.is-active {
    @media (max-width: 580px) {
      height: 100%;
      overflow: hidden;
    }
  }
}

*, *::before, *::after {
  box-sizing: border-box;
}

p,h1,h2,h3,h4,h5,h6,figure,ul,table,tr,th,td {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

img, svg {
  vertical-align: middle;
}

a {
  text-decoration: underline;
  color: $hama_blue_2;
  opacity: 1;
  transition: .3s;
  &:hover {
    text-decoration: none;
    opacity: .5;
  }
}

main {
  /*
  margin-top: 65px;
  @media (min-width: 581px) {
    margin-top: calc(88px + 70px);;
  }
  */
}