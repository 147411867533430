.l-newsinfo-single {
  border-top: 5px solid $hama_blue_2;
  padding: 60px 0 0 0;
  &__head {
    > time {
      display: block;
      position: relative;
      font-size: 0.750rem;
      line-height: 0.750rem;
      padding: 5px 0 5px 8px;
      color: $hama_white_1;
      background-color: $hama_blue_1;
      @media (min-width: 581px) {
        font-size: 0.875rem;
        line-height: 0.875rem;
        padding: 7px 0 7px 10px;
      }
      &::before {
        content: "";
        position: absolute;
        display: block;
        background-color: $hama_blue_2;
        height: 100%;
        width: calc(100% - 146px);
        top: 0;
        left: 146px;
        z-index: 0;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: calc(146px - 1.125rem);
        width: 1.125rem;
        height: 100%;
        background-color: $hama_blue_2;
        clip-path: polygon(100% 0, 100% 100%, 0 100%);
        @media (min-width: 581px) {
          left: calc(146px - 1.25rem);
          width: 1.25rem;
        }
      }
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 10px;
      background-color: $hama_white_3;
      text-decoration: none;
      >.is-category{
        flex: 0 0 auto;
        width: 57px;
        height: 57px;
        &.is-news {
          background-color: $hama_pink_1;
        }
        &.is-race {
          background-color: $hama_blue_5;
        }
        &.is-event {
          background-color: $hama_green_3;
        }
        > span {
          display: flex;
          align-items: center;
          justify-content: center;  
          text-align: center;
          width: 100%;
          height: 100%;
          font-size: 13px;
          font-weight: 600;
          color: $hama_white_1;
        }
      }
      >.is-detail{
        flex: 0 0 auto;
        width: calc(100% - 57px - 10px);
        height: 100%;
        >span {
          margin: 0;
          font-size:1.25rem;
          line-height: 1.625rem;
          font-weight: 600;
          color: $hama_black_1;
          @media (min-width: 581px) {
            font-size:1.625rem;
            line-height: 1.75rem;
          }
        }
      }
    }
  }
  &__wp-content {
    h2 {
      font-size: 1.5rem;
      line-height: 1.75rem;
      font-weight: 600;
      color: $hama_black_1;
      border-left: 10px solid $hama_blue_2;
      border-bottom: 1px solid $hama_blue_2;
      padding:11px 14px;
      background-color: $hama_white_3;
      margin-bottom: 30px;
    }
    h3 {
      font-size: 1.375rem;
      line-height: 1.5rem;
      font-weight: 600;
      color: $hama_black_1;
      padding: 0 0 8px 0;
      border-bottom: 1px solid $hama_blue_2;
      margin-bottom: 20px;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 400;
      color: $hama_black_1;
      margin-bottom: 30px;
    }
    strong {
      font-weight: 600;
    }
    em {
      font-style: italic;
    }
    a {
      color: $hama_blue_2;
      text-decoration: underline;
    }
    .is-btn-1 {
      display: inline-block;
      font-size: 20px;
      line-height: 20px;
      font-weight: 600;
      color: $hama_white_1;
      background-color: $hama_blue_1;
      padding: 5px 8px;
      border-radius: 4px;
      text-decoration: none;
      margin: 0 8px
    }
    figure {
      width: 100%;
      margin: 0 0 1rem 0;
      &.alignright{
        text-align: right;
      }
      &.alignleft{
        text-align: left;
      }
      &.aligncenter{
        text-align: center;
      }
      > img {
      }
    }
  }
}