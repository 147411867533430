.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-6 {
  margin-bottom: 6px;
}

.u-mb-8 {
  margin-bottom: 8px;
}

.u-mb-10 {
  margin-bottom: 10px;
}

.u-mb-12 {
  margin-bottom: 12px;
}

.u-mb-16 {
  margin-bottom: 16px;
}

.u-mb-20 {
  margin-bottom: 20px;
}

.u-mb-26 {
  margin-bottom: 26px;
}

.u-mb-30 {
  margin-bottom: 30px;
}

.u-mb-34 {
  margin-bottom: 34px;
}

.u-mb-40 {
  margin-bottom: 40px;
}

.u-mb-50 {
  margin-bottom: 50px;
}

.u-mb-60 {
  margin-bottom: 60px;
}

.u-mb-68 {
  margin-bottom: 68px;
}

.u-mb-90 {
  margin-bottom: 90px;
}

.u-mb-100 {
  margin-bottom: 100px;
}

.u-mb-120 {
  margin-bottom: 120px;
}

@media (min-width: 581px) {
  .u-mb-md-0 {
    margin-bottom: 0;
  }

  .u-mb-md-14 {
    margin-bottom: 14px;
  }

  .u-mb-md-20 {
    margin-bottom: 20px;
  }

  .u-mb-md-24 {
    margin-bottom: 24px;
  }

  .u-mb-md-30 {
    margin-bottom: 30px;
  }

  .u-mb-md-32 {
    margin-bottom: 32px;
  }

  .u-mb-md-34 {
    margin-bottom: 34px;
  }

  .u-mb-md-40 {
    margin-bottom: 40px;
  }

  .u-mb-md-60 {
    margin-bottom: 60px;
  }

  .u-mb-md-100 {
    margin-bottom: 100px;
  }
}

.u-mr-8 {
  margin-right: 8px;
}

.u-mr-18 {
  margin-right: 18px;
}

.u-mr-20 {
  margin-right: 20px;
}

.u-mr-60 {
  margin-right: 60px;
}

@media (min-width: 581px) {
  .u-mr-md-18 {
    margin-right: 18px;
  }

  .u-mr-md-40 {
    margin-right: 40px;
  }

  .u-mr-md-60 {
    margin-right: 60px;
  }
}