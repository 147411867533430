.l-front {
  &__information-box {
    padding: 26px 0;
    @media (min-width: 581px) {
      padding: 34px 0;
    }
    &__link-nav {
      background-color: $hama_white_1;
      padding: 12px 0;
      height: 100%;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-decoration: none;
      border: 4px solid $hama_white_1;
      &:hover {
        border: 4px solid $hama_blue_1;
        opacity: 1;
      }
      @media (min-width: 581px) {
        padding: 16px 0;       
      }
      > figure {
        width: 100%;
        margin: 0 0 5px 0;
        > img {
          display: inline-block;
          width: 56px;
          height: 56px;
          @media (min-width: 581px) {
            width: 70px;
            height: 70px;
          }
        }
      }
      > div {
        font-size: 0.8125rem;
        line-height: 0.9375rem;
        font-weight: 600;
        color: $hama_blue_1;
        @media (min-width: 581px) {
          font-size: 0.875rem;
          line-height: 1.3125rem;
        }
        > span {
        }
      }
    }
    &__info-nav {
      list-style: none;
      margin: 0;
      padding: 0 17px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (min-width: 581px) {
        padding: 0 29px;
      }
      &__item {
        display: block;
        flex: 0 0 auto;
        width: calc((100% - (17px * 3)) / 4);
        background-color: $hama_white_1;
        position:relative;
        height: 30px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        color: $hama_blue_1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media (min-width: 581px) {
          width: calc((100% - (29px * 3)) / 4);
          height: 50px;
          font-size: 22px;
          line-height: 36px;
          font-weight: 600;
        }
        &:hover {
          opacity: .5;
        }
        &::before {
          content:"";
          display: block;
          position: absolute;
          top: 0;
          left: -17px;
          width: 34px;
          height: 100%;
          background-color: $hama_white_1;
          clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0 100%);
          z-index: 0;
          @media (min-width: 581px) {
            left: -29px;
            width: 58px;
          }
        }
        &::after {
          content:"";
          display: block;
          position: absolute;
          top: 0;
          right: -17px;
          width: 34px;
          height: 100%;
          background-color: $hama_white_1;
          clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
          z-index: 0;
          @media (min-width: 581px) {
            right: -29px;
            width: 58px;
          }
        }
        &.is-current {
          background-color: $hama_blue_1;
          color: $hama_white_1;
          &::before {
            background-color: $hama_blue_1;
          }
          &::after {
            background-color: $hama_blue_1;
          }
        }
        > span {
          display: inline-block;
          position: relative;
          z-index: 10;
          &.is-new {
            margin-left: 14.4px;
            @media (min-width: 581px) {
              margin-left: 20px;
            }
            &::before{
              content:"";
              display: inline-block;
              height: 14.4px;
              width: 14.4px;
              position: absolute;
              left:-14.4px;
              top: 50%;
              transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              vertical-align: middle;
              background-image: url("../images/front/icon_info_new_sm.png");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              @media (min-width: 581px) {
                height: 20px;
                width: 20px;
                left:-20px;
              }
            }
          }
        }
      }
    }
    &__news {
      background-color: $hama_white_1;
      border-top: 3px solid $hama_blue_1;
      @media (min-width: 581px) {
        border-top: 5px solid $hama_blue_1;

        height: calc(100% - 132px);
      }
      &__item {
        display: none;
        height: 100%;
        &.is-current {
          display: block;
        }
        &__list {
          overflow-y: scroll;
          height: calc(68px * 6);
          @media (min-width: 581px) {
            height: 622px;
          }
          > ul{
            list-style: none;
            margin:0;
            padding:0;
             >li {
              display: block;
              
              position:relative;
              &::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                display: block;
                width: calc(100% - 16px);
                height: 1px;
                border-bottom: 1px dotted $hama_black_1;
                transform: translateX(-50%);
                @media (min-width: 581px) {
                  width: calc(100% - 20px);
                }
              }
              > a {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                text-decoration: none;
                padding: 8px;
                background-color: $hama_white_1;
                @media (min-width: 581px) {
                  padding: 10px;
                }
                &:hover {
                  background-color: $hama_gray_2;
                  opacity: 1;
                }
                >.is-category{
                  flex: 0 0 auto;
                  width: 45.6px;
                  height: 45.6px;
                  @media (min-width: 581px) {
                    width: 57px;
                    height: 57px;
                  }
                  &.is-news {
                    background-color: $hama_pink_1;
                  }
                  &.is-race {
                    background-color: $hama_blue_5;
                  }
                  &.is-event {
                    background-color: $hama_green_3;
                  }
                  > span {
                    display: flex;
                    align-items: center;
                    justify-content: center;  
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    font-size: 10px;
                    font-weight: 600;
                    color: $hama_white_1;
                    @media (min-width: 581px) {
                      font-size: 13px;
                    }
                  }
                }
                >.is-detail{
                  flex: 0 0 auto;
                  width: calc(100% - 45.6px - 10px);
                  height: 100%;
                  @media (min-width: 581px) {
                    width: calc(100% - 57px - 10px);
                  }
                  >p {
                    color: $hama_black_5;
                    font-size: 0.750rem;
                    line-height: 1.125rem;
                    @media (min-width: 581px) {
                      font-size: 0.875rem;
                      line-height: 1.25rem;
                    }
                    > time {
                      position: relative;
                      &.is-new {
                        &::after {
                          content: "";
                          display: inline-block;
                          width: 48px;
                          height:16px;
                          vertical-align: middle;
                          background-image: url("../images/front/icon_info_new_md.png");
                          background-position: center;
                          background-size: contain;
                          background-repeat: no-repeat;
                          margin: 0 0 0 20px;
                          @media (min-width: 581px) {
                            width: 60px;
                            height:20px;
                          }
                        }
                      }
                    }
                  }
                  >h3 {
                    margin: 0;
                    font-size:0.8125rem;
                    line-height: 1rem;
                    font-weight: 600;
                    color: $hama_black_4;
                    @media (min-width: 581px) {
                      font-size:1rem;
                      line-height: 1.25rem;
                    }
                  }
                }
              }
             }
          }
        }
        &__btn-wrap {
          text-align: center;
          padding: 10px 0;
          @media (min-width: 581px) {
            padding: 13px 0;
          }
          > a {
            display: inline-block;
            width:168px;
            height:1.5rem;
            font-size:0.875rem;
            line-height: 1.5rem;
            font-weight: 600;
            text-align: center;
            text-decoration: none;
            background-color: $hama_blue_2;
            color:$hama_white_1;
            border-radius:8px;
            @media (min-width: 581px) {
              width:210px;
              height:1.875rem;
              font-size:1.125rem;
              line-height: 1.875rem;
            }
          }
        }
      }
    }
  }
}