.p-head-race-ttl {
  position:relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 1.875rem;
  line-height: 1.875rem;
  font-weight: 700;
  color: $hama_black_1;
  background-color: $hama_blue_2;
  padding: 0 6px;
  @media (min-width: 581px) {
    height: 2.125rem;
    line-height: 2.125rem;
    padding: 0 8px;
  }
  &::before {
    content: "";
    position: absolute;
    display: block;
    background-color: $hama_white_2;
    height: 100%;
    width: 50%;
    top: 0;
    right: 0;
    z-index: 0;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: calc(50% - 1.125rem);
    width: 2.25rem;
    height: 100%;
    background-color: $hama_white_2;
    clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0 100%);
    @media (min-width: 581px) {
      width: 2.5rem;
    }
  }
  > span {
    position: relative;
    z-index: 10;
    display: inline-block;
    vertical-align: baseline;
    &.is-date{

    }
    > span {
      display: inline-block;
    }
  }
  .is-lg {
    font-size: 1.475rem;
    line-height: 1.875rem;
    @media (min-width: 581px) {
      font-size: 1.56375rem;
      line-height: 2.125rem;
    }
  }
  .is-md {
    font-size: 1.225rem;
    line-height: 1.875rem;
    @media (min-width: 581px) {
      font-size: 1.31375rem;
      line-height: 2.125rem;
    }
  }
  .is-sm {
    font-size: 0.975rem;
    line-height: 1.875rem;
    position: relative;
    bottom: 0.1rem;
    @media (min-width: 581px) {
      font-size: 1.06375rem;
      line-height: 2.125rem;
    }
  }
  .is-white {
    color: $hama_white_1;
  }
  .is-red {
    color: $hama_red_2;
  }
  .is-blue {
    color: $hama_blue_2;
  }
  .is-num{
    margin: 0 5px 0 0;
  }
  .is-month{
    margin: 0 12px 0 0;
    @media (min-width: 581px) {
      margin: 0 20px 0 0;
    }
  }
  .is-day{
    margin: 0 8px 0 0;
  }
  &.is-tomorrow {
    background-color: $hama_blue_1;
  }
}