.l-newsinfo {
  &__ttl {
    font-size: 1.8125rem;
    line-height: 2.375rem;
    color: $hama_blue_1;
    font-weight: 600;
    text-align: center;
    &__content {
      display: none;
      &.is-current {
        display: block;
      }
      > img {
        display: inline-block;
        width: 2.375rem;
        height: 2.375rem;
        margin-right: 0.875rem;
        vertical-align: bottom;
      }
    }
  }
  &__tab-list-box {
    border-top: 5px solid $hama_blue_2;
    border-bottom: 5px solid $hama_blue_1;
  }
  &__tab-list-wrap {
    width: 355px !important;
    @media (min-width: 581px) {
      width: 780px !important;;
    }
  }
  &__tab-list {
    list-style: none;
    margin: 0;
    padding: 0 17px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: 581px) {
      padding: 0 29px;
    }
    &__item {
      display: block;
      flex: 0 0 auto;
      position:relative;
      width: calc((100% - (17px * 3)) / 4);
      @media (min-width: 581px) {
        width: calc((100% - (29px * 3)) / 4);
      }
      > div {
        display: block;
        width: 100%;
        height: 30px;
        position:relative;
        text-decoration: none;
        background-color: $hama_white_1;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 581px) {
          height: 50px;
        }
        &::before {
          content:"";
          display: block;
          position: absolute;
          top: 0;
          left: -17px;
          width: 34px;
          height: 100%;
          background-color: $hama_white_1;
          clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0 100%);
          z-index: 0;
          @media (min-width: 581px) {
            left: -29px;
            width: 58px;
          }
        }
        &::after {
          content:"";
          display: block;
          position: absolute;
          top: 0;
          right: -17px;
          width: 34px;
          height: 100%;
          background-color: $hama_white_1;
          clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
          z-index: 0;
          @media (min-width: 581px) {
            right: -29px;
            width: 58px;
          }
        }
        > span {
          position: relative;
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
          color: $hama_blue_1;
          text-align: center;
          z-index:10;
          @media (min-width: 581px) {
            font-size: 22px;
            line-height: 22px;
          }
          &.is-new {
            margin-left: 14.4px;
            @media (min-width: 581px) {
              margin-left: 20px;
            }
            &::before{
              content:"";
              display: inline-block;
              height: 14.4px;
              width: 14.4px;
              position: absolute;
              left:-14.4px;
              top: 50%;
              transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              vertical-align: middle;
              background-image: url("../images/front/icon_info_new_sm.png");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              @media (min-width: 581px) {
                height: 20px;
                width: 20px;
                left:-20px;
              }
            }
          }
        }
      }
      &.is-current {
        > div {
          background-color: $hama_blue_1;
          &::before {
            background-color: $hama_blue_1;
          }
          &::after {
            background-color: $hama_blue_1;
          }
          > span {
            color: $hama_white_1;
          }
        }
      }
    }
  }
  &__information-box {
    padding: 26px 0;
    @media (min-width: 581px) {
      padding: 34px 0;
    }
    &__news {
      background-color: $hama_white_1;
      &__item {
        display: none;
        height: 100%;
        &.is-current {
          display: block;
        }
        &__list {
          overflow-y: scroll;
          height: calc(76px * 10);
          @media (min-width: 581px) {
            height: calc(93px * 10);
          }
          > ul{
            list-style: none;
            margin:0;
            padding:0;
             >li {
              display: block;
              
              position:relative;
              &::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                display: block;
                width: 100%;
                height: 1px;
                border-bottom: 1px dotted $hama_black_1;
                transform: translateX(-50%);
              }
              > a {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                text-decoration: none;
                padding: 8px;
                background-color: $hama_white_1;
                @media (min-width: 581px) {
                  padding: 18px 10px;
                }
                &:hover {
                  background-color: $hama_gray_2;
                  opacity: 1;
                }
                >.is-category{
                  flex: 0 0 auto;
                  width: 45.6px;
                  height: 45.6px;
                  @media (min-width: 581px) {
                    width: 57px;
                    height: 57px;
                  }
                  &.is-news {
                    background-color: $hama_pink_1;
                  }
                  &.is-race {
                    background-color: $hama_blue_5;
                  }
                  &.is-event {
                    background-color: $hama_green_3;
                  }
                  > span {
                    display: flex;
                    align-items: center;
                    justify-content: center;  
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    font-size: 10px;
                    font-weight: 600;
                    color: $hama_white_1;
                    @media (min-width: 581px) {
                      font-size: 13px;
                    }
                  }
                }
                >.is-detail{
                  flex: 0 0 auto;
                  width: calc(100% - 45.6px - 10px);
                  height: 100%;
                  @media (min-width: 581px) {
                    width: calc(100% - 57px - 10px);
                  }
                  >p {
                    color: $hama_black_5;
                    font-size: 0.750rem;
                    line-height: 1.125rem;
                    @media (min-width: 581px) {
                      font-size: 0.875rem;
                      line-height: 1.25rem;
                    }
                    > time {
                      position: relative;
                      &.is-new {
                        &::after {
                          content: "";
                          display: inline-block;
                          width: 48px;
                          height:16px;
                          vertical-align: middle;
                          background-image: url("../images/front/icon_info_new_md.png");
                          background-position: center;
                          background-size: contain;
                          background-repeat: no-repeat;
                          margin: 0 0 0 20px;
                          @media (min-width: 581px) {
                            width: 60px;
                            height:20px;
                          }
                        }
                      }
                    }
                  }
                  >h3 {
                    margin: 0;
                    font-size:0.8125rem;
                    line-height: 1rem;
                    font-weight: 600;
                    color: $hama_black_4;
                    @media (min-width: 581px) {
                      font-size:1rem;
                      line-height: 1.25rem;
                    }
                  }
                }
              }
             }
          }
        }
      }
    }
  }
}