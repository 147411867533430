.p-bread-crumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 8px 0;
  > li {
    display: inline-block;
    color: $hama_black_1;
    font-size: 14px;
    line-height: 24px;
    &:not(:last-child)::after{
      display: inline-block;
      content:"▶︎";
      color:$hama_blue_2;
      margin: 0 8px;
    }
    > a {
      color: $hama_black_1;
    }
  }
}