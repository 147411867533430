.p-head-race-schedule {
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    display: flex;
    position: relative;
    justify-content: space-between;
    &:first-child {
      padding-bottom: 11px;
      border-bottom: 1px dotted $hama_black_1;
      margin-bottom: 11px;
      @media (min-width: 581px) {
        padding-bottom: 12px;
        margin-bottom: 12px;
      }
    }
    > p {
      display: flex;
	    align-items: center;
      margin: 0;
      &.is-held {
        width: 46px;
        margin-right: 12px;
        @media (min-width: 581px) {
          width: 52px;
          margin-right: 14px;
        }
        &.is-authentic {
          background-color: $hama_red_1;
        }
        &.is-outside {
          background-color: $hama_green_1;
        }
        > span {
          display: inline-block;
          width: 100%;
          color: $hama_white_1;
          font-size: 0.875rem;
          line-height: 0.875rem;
          font-weight:800;
          margin: 0;
          text-align: center;
          @media (min-width: 581px) {
            font-size: 1rem;
            line-height: 1rem;
          }
        }
      }
    }
    > div {
      &.is-detail {
        width: 100%;
        > p {
          font-size: 0.875rem;
          line-height: 1.25rem;
          font-weight: 600;
          color: $hama_black_1;
          @media (min-width: 581px) {
            font-size: 1rem;
            line-height: 1.375rem;
          }
        }
      }
    }
  }
}