.p-list-1 {
  list-style: none;
  font-size: 0.750rem;
  line-height: 1.25rem;
  color: $hama_black_1;
  @media (min-width: 581px) {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
  > li {
    padding-left: 1em;
    position: relative;
    &::before {
      content:"・";
      display: inline;
      position: absolute;
      left: 0;
    }
  }
}

.p-list-2 {
  list-style: none;
  font-size: 0.750rem;
  line-height: 1.25rem;
  color: $hama_black_1;
  @media (min-width: 581px) {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
  > li {
    padding-left: 1em;
    position: relative;
    &::before {
      content:"※";
      display: inline;
      position: absolute;
      left: 0;
    }
  }
}