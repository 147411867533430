.p-follow-content {
  display: block;
  position: fixed;
  width: 80px;
  height: 80px;
  right: 10px;
  bottom: 40px;
  z-index: 9000;
  cursor: pointer;
  > a {
    display: block;
  }
}