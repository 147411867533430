.l-footer {
  background-color: $hama_blue_1;
  color: $hama_white_1;
  padding: 26px 0 55px 0;
  @media (min-width: 581px) {
    padding: 40px 0 170px 0;
  }
  &__logotype {
    >img {
      max-width: 330px;
      @media (min-width: 581px) {
        max-width: 350px;
      }
    }
  }
}