.u-ta-c {
  text-align: center;
}

.u-c__white-1 {
  color: $hama_white_1;
}

.u-c__black-4 {
  color: $hama_black_4;
}

.u-c__blue-2 {
  color: $hama_blue_2;
}

.u-c__red-2 {
  color: $hama_red_2;
}

@media (min-width: 581px) {
  .u-ta-md-c {
    text-align: center;
  } 
}