.p-global-nav {
  display: block;
  background-color: $hama_blue_2;
  color: $hama_white_1;
  position: sticky;
  left: 0;
  width: 100%;
  z-index: 10000;
  @media (max-width: 580px) {
    top: 65px;
    height: 0;
    min-height: 0;
    overflow: hidden;
    transition: .3s;
  }
  @media (min-width: 581px) {
    top: 88px;
    height: 70px;
    height: auto!important;
  }
  &.is-active {
    @media (max-width: 580px) {
      height: auto;
      min-height: 204px;
      max-height: calc(100% - 65px);
      overflow-y: scroll;
      transition: .3s;
    }
  }
  &__container {
    @media (max-width: 580px) {
      height: auto;
    }
    nav {
      > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        @media (min-width: 581px) {
          display: flex;
          flex-wrap: wrap;
        }
        > li {
          position: relative;
          @media (min-width: 581px) {
            flex: 0 0 auto;
            width: 20%;
          }
          &:not(:last-child) {
            @media (max-width: 580px) {
              border-bottom: 1px solid $hama_white_1;
            }
          }
          &::after {
            @media (min-width: 581px) {
              content:"";
              position:absolute;
              left: 50%;
              transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              bottom:0;
              display: block;
              height: 1px;
              width: 0;
              background-color: $hama_white_1;
              transition: .3s;
            }
          }
          &:hover {
            &::after {
              @media (min-width: 581px) {
                width: 100%;
                transition: .3s;
              }
            }
          }
          > .is-item {
            position: relative;
            display: block;
            color: $hama_white_1;
            text-decoration: none;
            font-weight: 600;
            @media (max-width: 580px) {
              font-size: 1.125rem;
              line-height: 2rem;
              padding: 4px 4px 4px 50px;
            }
            @media (min-width: 581px) {
              font-size: 1rem;
              line-height: 1.25rem;
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 70px;
            }
            > span{
              display: block;
              @media (min-width: 581px) {
                position: relative;
                font-size: 0;
              }
              > img {
                display: none;
                @media (min-width: 581px) {
                  display: inline-block;
                  vertical-align: middle;
                  width: 38px;
                  height: 38px;
                  margin: 0 10px 0 0;
                }
                &.is-info {
                  @media (min-width: 581px) {
                    width: 30px;
                    height: 30px;
                    margin: 0 6px 0 0;
                  } 
                }
              }
              > span {
                display: block;
                @media (min-width: 581px) {
                  display: inline-block;
                  vertical-align: middle;
                }
                > span {
                  display: block;
                  &.is-jp {
                    @media (min-width: 581px) {
                      font-size:1rem;
                      line-height: 1rem;
                      margin: 0 0 0.25rem 0;
                      text-align: center;
                    }
                  }
                  &.is-en {
                    display: none;
                    @media (min-width: 581px) {
                      display: block;
                      font-size:0.5rem;
                      line-height: 0.5rem;
                      text-align: center;
                      margin: 0;
                      letter-spacing: 0.06125rem;
                    }
                  }
                }
              }
            }
            &:hover {
              opacity: 1;
              background-color: $hama_blue_3;
            }
          }
          > ul {
            @media (max-width: 580px) {
              list-style: none;
              margin: 0;
              padding: 0;
              height: 0;
              overflow: hidden;
              transition: .3s;
            }
            @media (min-width: 581px) {
              display: none;
            }
            &.is-active {
              @media (max-width: 580px) {
                height: auto;
                overflow-y: scroll;
                transition: .3s;
              }
              &.is-guide {
                @media (max-width: 580px) {
                  height:calc((2rem + 8px) * 3);
                }
              }
              &.is-info {
                @media (max-width: 580px) {
                  height:calc((2rem + 8px) * 3);
                }
              }
            }
            > li {
              > a {
                @media (max-width: 580px) {
                  display: block;
                  font-size: 1.125rem;
                  line-height: 2rem;
                  font-weight: 400;
                  color: $hama_white_1;
                  text-decoration: none;
                  padding: 4px 4px 4px 50px;
                }
              }
            }
          }
        }
      }
    }
  }
  &__accordion {
    &::before {
      @media (max-width: 580px) {
        content:"";
        display: block;
        width:1.125rem;
        height: 0.125rem;
        background-color: $hama_white_1;
        position:absolute;
        top:calc(((2rem + 8px) - 0.125rem) / 2);
        left:22px;
      }
    }
    &::after {
      @media (max-width: 580px) {
        content:"";
        display: block;
        width:0.125rem;
        height: 1.125rem;
        background-color: $hama_white_1;
        position:absolute;
        top:calc(((2rem + 8px) - 1.125rem) / 2);
        left:calc(22px + ((1.125rem - 0.125rem) / 2));
      }
    }
    &.is-active {
      @media (max-width: 580px) {
        background-color: $hama_blue_3;
      }
      &::before{

      }
      &::after {
        @media (max-width: 580px) {
          display: none;
        }
      }
    }
  }
  &__mega-menu {
    position: relative;
    &:hover {
      > p {
        @media (min-width: 581px) {
          opacity: 1;
          background-color: $hama_blue_3;
        }
      }
      > ul {
        @media (min-width: 581px) {
          display: block!important;
          position:absolute;
          list-style: none;
          padding: 0;
          margin: 0;
          width: 100%;
          background-color: $hama_blue_2;
          border-bottom: 1px solid $hama_white_1;
        }
        > li {
          @media (min-width: 581px) {
            display: block;
          }
          > a {
            @media (min-width: 581px) {
              display: block;
              background-color: $hama_blue_2;
              color: $hama_white_1;
              text-decoration: none;
              font-size: 1rem;
              line-height: 1.25rem;
              font-weight: 600;
              padding: 10px 0;
              text-align: center;
            }
            &:hover {
              @media (min-width: 581px) {
                opacity: 1;
                background-color: $hama_blue_3;
              }
            }
          }
        }
      }
    }
  }
}