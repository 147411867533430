.p-footer-nav-1 {
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    > li {
      display: block;
      flex: 0 0 auto;
      width: 100%;
      @media (min-width: 581px) {
        width: 20%;
        padding: 0 25px 0 0;
      }
      > p {
        position: relative;
        font-size: 1.125rem;
        line-height: 2rem;
        font-weight: 600;
        color: $hama_white_1;
        padding: 0 0 0 1rem;
        @media (min-width: 581px) {
          padding: 0 0 0 1.25rem;
        }
        &::before {
          content:"";
          display: block;
          height: 0.875rem;
          width: 0.5rem;
          background-color: $hama_white_1;
          clip-path: polygon(0 0, 100% 50%, 0 100%);
          position: absolute;
          top: 0.5em;
          left: 0;
        }
      }
      > ul {
        list-style: none;
        margin: 0;
        padding: 0 0 0 1rem;
        @media (min-width: 581px) {
          padding: 0 0 0 1.25rem;
        }
        > li {
          > a {
            color: $hama_white_1;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.p-footer-nav-2 {
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    > li {
      display: inline-block;
      position: relative;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 600;
      color: $hama_white_1;
      &:not(:last-child)::after {
        content: "|";
        display: inline-block;
        margin:0 0.5em;
      }
      > a {
        color: $hama_white_1;
        text-decoration: none;
      }
    }
  }
}