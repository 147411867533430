@import "foundation/normalize.css";
@import "foundation/slick.css";

@import "foundation/variable";

@import "foundation/base";

@import "layout/header";
@import "layout/footer";
@import "layout/front";
@import "layout/calendar";
@import "layout/homeplayer";
@import "layout/homeplayer-detail";
@import "layout/newsinfo";
@import "layout/newsinfo-single";
@import "layout/flag";
@import "layout/contact";
@import "layout/link";
@import "layout/present";
@import "layout/fixed-page";

@import "object/project/hamburger-btn";
@import "object/project/global-nav";
@import "object/project/follow-content";
@import "object/project/footer-nav";
@import "object/project/front-info-box";
@import "object/project/head-race-ttl";
@import "object/project/head-race-schedule";
@import "object/project/slick";
@import "object/project/bread-crumbs";
@import "object/project/ttl";
@import "object/project/btn";
@import "object/project/table";
@import "object/project/pagination";
@import "object/project/list";
@import "object/project/notice-box";

@import "object/component/text";
@import "object/component/icon";
@import "object/component/twitter-embed";
@import "object/component/link";

@import "object/utility/container";
@import "object/utility/row";
@import "object/utility/column";
@import "object/utility/text";
@import "object/utility/margin";
@import "object/utility/display";
@import "object/utility/bg";

.grecaptcha-badge {
  margin-bottom: 140px;
}