.l-homeplayer {
  &__tab-list-box {
    border-top: 5px solid $hama_blue_2;
    border-bottom: 5px solid $hama_blue_1;
  }
  &__tab-list-wrap {
    width: 269px !important;
    @media (min-width: 581px) {
      width: 593px !important;;
    }
  }
  &__tab-list {
    list-style: none;
    margin: 0;
    padding: 0 17px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: 581px) {
      padding: 0 29px;
    }
    &__item {
      display: block;
      flex: 0 0 auto;
      position:relative;
      width: calc((100% - (17px * 2)) / 3);
      @media (min-width: 581px) {
        width: calc((100% - (29px * 2)) / 3);
      }
      > a {
        display: block;
        width: 100%;
        height: 30px;
        position:relative;
        text-decoration: none;
        background-color: $hama_white_1;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 581px) {
          height: 50px;
        }
        &::before {
          content:"";
          display: block;
          position: absolute;
          top: 0;
          left: -17px;
          width: 34px;
          height: 100%;
          background-color: $hama_white_1;
          clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0 100%);
          z-index: 0;
          @media (min-width: 581px) {
            left: -29px;
            width: 58px;
          }
        }
        &::after {
          content:"";
          display: block;
          position: absolute;
          top: 0;
          right: -17px;
          width: 34px;
          height: 100%;
          background-color: $hama_white_1;
          clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
          z-index: 0;
          @media (min-width: 581px) {
            right: -29px;
            width: 58px;
          }
        }
        > div {
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
          color: $hama_blue_1;
          text-align: center;
          z-index:10;
          @media (min-width: 581px) {
            font-size: 22px;
            line-height: 22px;
          }
        }
      }
      &.is-current {
        > a {
          background-color: $hama_blue_1;
          &::before {
            background-color: $hama_blue_1;
          }
          &::after {
            background-color: $hama_blue_1;
          }
          > div {
            color: $hama_white_1;
          }
        }
      }
    }
  }
  &__card {
    background-color: $hama_white_3;
    padding: 8px;
    display: block;
    text-decoration: none;
    @media (min-width: 581px) {
      padding: 10px;
    }
    > div {
      background-color: $hama_white_1;
      > figure {
        position: relative;
        width: 100%;
        aspect-ratio: 156 / 176;
        @media (min-width: 581px) {
          aspect-ratio: 265 / 220;
        }
        > img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          object-fit: cover;
        }
      }
      >.is-name {
        font-size:1.25rem;
        line-height: 1.25rem;
        font-weight: 600;
        color:$hama_blue_1;
        text-align: center;
        @media (min-width: 581px) {
          font-size: 1.375rem;
          line-height: 1.375rem;
        }
      }
      >.is-kana {
        font-size: 0.750rem;
        line-height: 0.750rem;
        font-weight: 600;
        color:$hama_blue_1;
        text-align: center;
        opacity: .6;
      }
      >.is-season {
        font-size: 0.875rem;
        line-height: 0.875rem;
        font-weight: 600;
        color:$hama_black_1;
        text-align: center;
      }
      >.is-rank {
        background-color: $hama_blue_1;
        > p{
          font-size:0.875rem;
          line-height: 1.75rem;
          font-weight: 600;
          color:$hama_white_1;
          text-align: center;
        }
      }
    }
  }
  &__sns-list {
    &.is-detail {
      display: inline-block;
      > ul {
        width: 188px;
        justify-content: start;
        > li {
          > a {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      > li {
        display: block;
        padding: 0 10px;
        flex: 0 0 auto;
        &:first-child{
          padding: 0 10px 0 0;
        }
        &:last-child{
          padding: 0 0 0 10px;
        }
        > a {
          display: block;
          width: 26px;
          height: 26px;
          @media (min-width: 581px) {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
  &__sort-btn {
    
  }
  &__list {
    display: none;
    &.is-current {
      display: block;
    }
  }
}