.c-icon {
  &__g1-s {
    display: inline-block;
    width: 18px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_g1_s.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 20px;
      height:20px;
    }
  }
  &__g1-l {
    display: inline-block;
    width: 62px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_g1_l.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 70px;
      height:20px;
    }
  }
  &__g2-s {
    display: inline-block;
    width: 18px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_g2_s.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 20px;
      height:20px;
    }
  }
  &__g2-l {
    display: inline-block;
    width: 62px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_g2_l.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 70px;
      height:20px;
    }
  }
  &__sg-s {
    display: inline-block;
    width: 18px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_sg_s.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 20px;
      height:20px;
    }
  }
  &__sg-l {
    display: inline-block;
    width: 62px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_sg_l.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 70px;
      height:20px;
    }
  }
  &__sg1-s {
    display: inline-block;
    width: 18px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_sg1_s.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 20px;
      height:20px;
    }
  }
  &__sg1-l {
    display: inline-block;
    width: 62px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_sg1_l.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 70px;
      height:20px;
    }
  }
  &__usually-s {
    display: inline-block;
    width: 18px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_usually_s.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 20px;
      height:20px;
    }
  }
  &__usually-l {
    display: inline-block;
    width: 62px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_usually_l.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 70px;
      height:20px;
    }
  }
  &__early-s {
    display: inline-block;
    width: 18px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_early_s.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 20px;
      height:20px;
    }
  }
  &__early-l {
    display: inline-block;
    width: 62px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_early_l.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 70px;
      height:20px;
    }
  }
  &__event-s {
    display: inline-block;
    width: 18px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_event_s.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 20px;
      height:20px;
    }
  }
  &__event-l {
    display: inline-block;
    width: 62px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_event_l.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 70px;
      height:20px;
    }
  }
  &__cs528ch {
    display: inline-block;
    width: 80px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_cs528.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 90px;
      height:20px;
    }
  }
  &__cs529ch {
    display: inline-block;
    width: 80px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_cs529.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 90px;
      height:20px;
    }
  }
  &__held-early {
    display: inline-block;
    width: 18px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_early.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 20px;
      height:20px;
    }
  }
  &__held-day {
    display: inline-block;
    width: 18px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_day.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 20px;
      height:20px;
    }
  }
  &__held-nighter {
    display: inline-block;
    width: 18px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_nighter.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 20px;
      height:20px;
    }
  }
  &__held-midnight {
    display: inline-block;
    width: 18px;
    height:18px;
    vertical-align: middle;
    background-image: url("../images/icon/calendaricon_midnight.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    position: relative;
    top: -0.1em;
    @media (min-width: 581px) {
      width: 20px;
      height:20px;
    }
  }
}