.l-calendar {
  &__tab-list-box {
    border-top: 5px solid $hama_blue_2;
    border-bottom: 5px solid $hama_blue_1;
  }
  &__tab-list-wrap {
    @media (min-width: 581px) {
      width: calc(100% - 190px);
    }
  }
  &__tab-list {
    list-style: none;
    margin: 0;
    padding: 0 17px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: 581px) {
      padding: 0 29px;
    }
    &__item {
      display: block;
      flex: 0 0 auto;
      width: calc((100% - (17px * 8)) / 9);
      background-color: $hama_white_1;
      position:relative;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media (min-width: 581px) {
        width: calc((100% - (29px * 8)) / 9);
        height: 50px;
      }
      &:hover {
        opacity: .5;
      }
      &::before {
        content:"";
        display: block;
        position: absolute;
        top: 0;
        left: -17px;
        width: 34px;
        height: 100%;
        background-color: $hama_white_1;
        clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0 100%);
        z-index: 0;
        @media (min-width: 581px) {
          left: -29px;
          width: 58px;
        }
      }
      &::after {
        content:"";
        display: block;
        position: absolute;
        top: 0;
        right: -17px;
        width: 34px;
        height: 100%;
        background-color: $hama_white_1;
        clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
        z-index: 0;
        @media (min-width: 581px) {
          right: -29px;
          width: 58px;
        }
      }
      > div {
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        color: $hama_blue_1;
        text-align: center;
        z-index:10;
        @media (min-width: 581px) {
          font-size: 22px;
          line-height: 22px;
        }
      }
      &.is-current {
        background-color: $hama_blue_1;
        &::before {
          background-color: $hama_blue_1;
        }
        &::after {
          background-color: $hama_blue_1;
        }
        > div {
          color: $hama_white_1;
        }
      }
      &.is-no-select{
        cursor: unset;
        &:hover {
          opacity: unset;
        }
        background-color: $hama_gray_3;
        &::before {
          background-color: $hama_gray_3;
        }
        &::after {
          background-color: $hama_gray_3;
        }
        > div {
        }
      }
    }
  }
  &__select-box {
    position: relative;
    width: 200px;
    margin: 0 auto;
    &::-ms-expand {
      display: none;
    }
    &::after {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      right: 10px;
      top: 5px;
      position: absolute;
      pointer-events: none;
      background-image: url("../images/common/select-icon.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    select {
      padding: 0 10px;
      margin-bottom: 5px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      width: 100%;
      max-width: 200px;
      height: 26px;
      font-size: 16px;
      line-height: 26px;
      color: $hama_black_1;
      background-color: $hama_white_1;
      background-image: none;
      border: 1px solid $hama_gray_10;
      border-radius: 8px;
      -ms-word-break: normal;
      word-break: normal;
    }
  }
  &__content-wrap {
    overflow: hidden;
  }
  &__content {
    display: flex;
    width: calc((100vw - 20px)* 9);
    transform: translateX(calc(-1 * (100vw - 20px)));
    transition: transform .6s;
    @media (min-width: 581px) {
      width: calc(1200px* 9);
      transform: translateX(-1200px);  
    }

    &__calendar {
      //width: 355px;
      @media (max-width: 580px) {
        width: 100%;
        max-width: calc(100vw - 20px);
      }
      @media (min-width: 581px) {
        width: 1200px;
      }
      &__ttl {
        > .is-y {
          font-size: 1rem;
          font-weight: 700;
          color: $hama_black_1;
          margin-right: 27px;
        }
        > .is-m {
          font-size: 76px;
          font-weight: 700;
          color: $hama_blue_2;
          margin-right: 10px;
        }
        > .is-text {
          font-size: 32px;
          font-weight: 700;
          color: $hama_blue_2;
        }
      }
      &__wrap {
        @media (max-width: 580px) {
          overflow-x: scroll;
        }
      }
      table {
        width: 1200px;
        height: 583px;
        border-collapse: collapse;
        border-spacing: 0;
        th,td {
          border: 1px solid $hama_gray_3;
          font-size: 12px;
          color: $hama_black_1;
          text-align: center;
        }
        th {
          width: 115px;
        }
        td {
          width: 35px;
          a {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-decoration: none;
            position: relative;
            &.is-no-link {
              pointer-events: none;              
              cursor: default;
            }
            div {
              width: 100%;
              color: $hama_black_1;
              font-size: 12px;
              line-height: 24px;
            }
          }
          .is-cancel {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-decoration: none;
            position: relative;
            background-color: $hama_black_1;
            div {
              width: 100%;
              color: $hama_white_1;
              font-size: 12px;
              line-height: 24px;
            }
          }
        }
        .is-date-head {
          background-color: $hama_blue_2;
          color:$hama_white_1;
          font-size: 16px;
          font-weight: 700;
        }
        .is-home-head {
          background-color: $hama_red_1;
          color:$hama_white_1;
          font-size: 16px;
          font-weight: 700;
        }
        .is-away-head {
          background-color: $hama_green_4;
          color:$hama_white_1;
          font-size: 16px;
          font-weight: 700;
        }
        .is-event-head {
          background-color: $hama_orange_1;
          color:$hama_white_1;
          font-size: 16px;
          font-weight: 700;
        }
        .is-date-row {
          &.is-num {
            height: 36px;
          }
          &.is-day {
            height: 24px;
          }
          .sat {
            background-color: $hama_blue_6;
            color: $hama_white_1;
          }
          .sun {
            background-color: $hama_red_3;
            color: $hama_white_1;
          }
        }
        .is-home-row {
          height: 162px;
          td {
            height: 162px;
            a {
              background-color: $hama_red_4;
            }
          }
        }
        .is-info-row {
          height: 162px;
          td {
            height: 162px;
            a {
              background-color: $hama_green_5;
            }
          }
        }
        .is-event-row {
          height: 36px;
          td {
            height: 36px;
            a {
              background-color: $hama_orange_2;
            }
          }
        }
      }
    }
  }
}