.l-fixed-page {
  border-top: 5px solid $hama_blue_2;
  padding: 60px 0 0 0;
  &.is-gourmet {
    padding: 0;
  }
  &.is-accessmap {
    padding: 34px 0 0 0;
    @media (min-width: 581px) {
      padding: 60px 0 0 0;
    }
  }
}

.l-gourmet {
  &__ttl {
    padding: 15px;
    @media (min-width: 581px) {
      padding: 18px;
    }
    > figure {
      @media (min-width: 581px) {
        width: 564px;
        height: 304px;
      }
      > img {
        width: 324px;
        height: 170px;
        @media (min-width: 581px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  &__img {
    > figure {
      @media (min-width: 581px) {
        width: 600px;
        height: 340px;
      }
      > img {
        width: 354px;
        height: 200px;
        @media (min-width: 581px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  &__time {
    display: inline-block;
    background-color: $hama_blue_2;
    color: $hama_white_1;
    font-size: 0.875rem;
    line-height: 1.625rem;
    width: 70px;
    text-align: center;
  }
  &__hr {
    border-top: 1px solid $hama_blue_2;
  }
}

.l-accessmap {
  &__nav-btn {
    display: block;
    background-color: $hama_blue_2;
    text-decoration: none;
    color: $hama_white_1;
    text-align: center;
    padding: 4px 0 4px 0;
    border-radius: 6px;
    height: 78px;
    @media (min-width: 581px) {
      padding: 6px 0 6px 0;
      height: 98px;
    }
    &:hover {
      opacity: 1;
      background-color: $hama_blue_1;
    }
    > .is-icon {
      > img {
        height: 37px;
        @media (min-width: 581px) {
          height: 46px;
        }
      }
    }
    >.is-text {
      display: block;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      @media (min-width: 581px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    > .is-arrow {
      > img {
        width: 18px;
      }
    }
  }
  &__map-1 {
    height: 500px;
    > iframe {
      width: 100%;
      height: 100%;
    }
  }
  &__notice {
    background-color: $hama_white_1;
    font-size: 1rem;
    line-height: 1.125rem;
    font-weight: 600;
    text-align: center;
    color:$hama_red_2;
    border: 4px solid $hama_red_2;
    padding: 12px;
    @media (min-width: 581px) {
      font-size: 1.375rem;
      line-height: 1.375rem;
      padding: 26px;
    }
  }
  &__map-2 {
    position: relative;
    width: 100%;
    height: 208px;
    overflow: hidden;
    @media (min-width: 581px) {
      width: 100%;
      height: calc(15rem + 100px);
    }
    > img {
      display: block;
      position:absolute;
      width: auto;
      max-width: unset;
      height: 100%;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
    }
  }
  &__map-3 {
    height: 500px;
    @media (min-width: 581px) {
      height: 840px;
    }
    > iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.l-race-venus {
  &__main-visual-box {
    display: flex;
    align-items: flex-end;
  }
  &__sns-list {
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(242,148,25,1) 50%, rgba(0,0,0,0) 100%);
    padding: 18px 0;
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      > li {
        display: block;
        padding: 0 9px;
        flex: 0 0 auto;
        &:first-child{
          padding: 0 9px 0 0;
        }
        &:last-child{
          padding: 0 0 0 9px;
        }
        > a {
          display: block;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  &__img-l {
    position: relative;
    > figure {
      position: absolute;
      bottom: 120px;
    }
  }
  &__img-r {
    position: relative;
    > figure {
      position: absolute;
      top: -60px;
    }
  }
}

.l-hamarich {
  &__tab-list-box {
    border-top: 5px solid $hama_blue_2;
    border-bottom: 5px solid $hama_blue_1;
  }
  &__tab-list-wrap {
    width: 269px !important;
    @media (min-width: 581px) {
      width: 674px !important;;
    }
  }
  &__tab-list {
    list-style: none;
    margin: 0;
    padding: 0 17px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: 581px) {
      padding: 0 29px;
    }
    &__item {
      display: block;
      flex: 0 0 auto;
      position:relative;
      width: calc((100% - (17px * 2)) / 3);
      @media (min-width: 581px) {
        width: calc((100% - (29px * 2)) / 3);
      }
      > a {
        display: block;
        width: 100%;
        height: 30px;
        position:relative;
        text-decoration: none;
        background-color: $hama_white_1;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 581px) {
          height: 50px;
        }
        &::before {
          content:"";
          display: block;
          position: absolute;
          top: 0;
          left: -17px;
          width: 34px;
          height: 100%;
          background-color: $hama_white_1;
          clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0 100%);
          z-index: 0;
          @media (min-width: 581px) {
            left: -29px;
            width: 58px;
          }
        }
        &::after {
          content:"";
          display: block;
          position: absolute;
          top: 0;
          right: -17px;
          width: 34px;
          height: 100%;
          background-color: $hama_white_1;
          clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
          z-index: 0;
          @media (min-width: 581px) {
            right: -29px;
            width: 58px;
          }
        }
        > div {
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
          color: $hama_blue_1;
          text-align: center;
          z-index:10;
          @media (min-width: 581px) {
            font-size: 22px;
            line-height: 22px;
          }
        }
      }
      &.is-current {
        > a {
          background-color: $hama_blue_1;
          &::before {
            background-color: $hama_blue_1;
          }
          &::after {
            background-color: $hama_blue_1;
          }
          > div {
            color: $hama_white_1;
          }
        }
      }
    }
  }
  &__ttl {
    text-align: center;
    > img {
      width: 354px;
      @media (min-width: 581px) {
        width: 634px;
      }
    }
  }
  &__img-1 {
    text-align: center;
    > img {
      width: 176px;
      @media (min-width: 581px) {
        width: 100%;
      }
    }
  }
  &__img-2 {
    text-align: center;
    > img {
      width: 236px;
      @media (min-width: 581px) {
        width: 100%;
      }
    }
  }
  &__img-3 {
    text-align: center;
    > img {
      width: 184px;
      @media (min-width: 581px) {
        width: 100%;
      }
    }
  }
  &__text-box {
    @media (min-width: 581px) {
      height: 100%;
      position: relative;
    }
    > p {
      @media (min-width: 581px) {
        position:absolute;
        top:50%;
        left:0;
        transform: translateY(-50%);
      }
    }
  }
  &__content-1 {
    padding: 10px;
  }
  &__link-1 {
    display: block;
  }
}

.l-facility {
  &__tab-list-box {
    border-top: 5px solid $hama_blue_2;
    border-bottom: 5px solid $hama_blue_1;
  }
  &__tab-list-wrap {
    width: 345px !important;
    @media (min-width: 581px) {
      width: 889px !important;;
    }
  }
  &__tab-list {
    list-style: none;
    margin: 0;
    padding: 0 17px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: 581px) {
      padding: 0 29px;
    }
    &__item {
      display: block;
      flex: 0 0 auto;
      position:relative;
      width: calc((100% - (17px * 3)) / 4);
      @media (min-width: 581px) {
        width: calc((100% - (29px * 3)) / 4);
      }
      > a {
        display: block;
        width: 100%;
        height: 30px;
        position:relative;
        text-decoration: none;
        background-color: $hama_white_1;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 581px) {
          height: 50px;
        }
        &::before {
          content:"";
          display: block;
          position: absolute;
          top: 0;
          left: -17px;
          width: 34px;
          height: 100%;
          background-color: $hama_white_1;
          clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0 100%);
          z-index: 0;
          @media (min-width: 581px) {
            left: -29px;
            width: 58px;
          }
        }
        &::after {
          content:"";
          display: block;
          position: absolute;
          top: 0;
          right: -17px;
          width: 34px;
          height: 100%;
          background-color: $hama_white_1;
          clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
          z-index: 0;
          @media (min-width: 581px) {
            right: -29px;
            width: 58px;
          }
        }
        > div {
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
          color: $hama_blue_1;
          text-align: center;
          z-index:10;
          @media (min-width: 581px) {
            font-size: 22px;
            line-height: 22px;
          }
        }
      }
      &.is-current {
        > a {
          background-color: $hama_blue_1;
          &::before {
            background-color: $hama_blue_1;
          }
          &::after {
            background-color: $hama_blue_1;
          }
          > div {
            color: $hama_white_1;
          }
        }
      }
    }
  }
  &__img-1 {
    text-align: center;
    > img {
      width: 100%;
      @media (min-width: 581px) {
        width: 1028px;
      }
    }
  }
  &__info {
    display: inline-block;
    position:relative;
    line-height: 20px;
    margin-right: 1rem;
    @media (min-width: 581px) {
      line-height: 26px;
      margin-right: 2.5rem;
    }
    > img {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      @media (min-width: 581px) {
        width: 26px;
        height: 26px;
      }
    }
  }
  &__card-1 {
    height: 100%;
    > h4 {
      color: $hama_white_1;
      font-weight: 600;
      text-align: center;
      font-size: 1.0625rem;
      line-height: 1.0625rem;
      padding: 8px;
      @media (min-width: 581px) {
        font-size: 1.375rem;
        line-height: 1.375rem;
        padding: 9px;
      }
    }
    > p{
      padding: 14px;
      @media (min-width: 581px) {
        padding: 20px;
      }
    }
    &.is-red {
      border: 3px solid $hama_red_2;
      > h4{
        background-color: $hama_red_2;
      }
    }
    &.is-green {
      border: 3px solid $hama_green_6;
      > h4{
        background-color: $hama_green_6;
      }
    }
  }
}

.l-startinglist {
  &__card-1{
    background-color: $hama_yellow_1;
    height: 100%;
    padding: 20px;
    @media (min-width: 581px) {
      padding: 30px;
    }
    > p {
      text-align: center;
      font-size: 28px;
      line-height: 32px;
      font-weight: 600;
      @media (min-width: 581px) {
        font-size: 40px;
        line-height: 46px;
      }
    }
    > a {
      display: block;
    }
  }
  &__ttl-1 {
    position: relative;
    display: block;
    font-size: 17px;
    line-height: 18px;
    font-weight: 600;
    color: $hama_white_1;
    background-color: $hama_blue_1;
    width: 204px;
    height: 40px;
    padding: 11px 0;
    text-align: center;
    border-radius: 20px;
    margin: 0 auto;
    @media (min-width: 581px) {
      font-size: 22px;
      line-height: 22px;
      width: 255px;
      padding: 9px 0;
    }
    > span {
      display: block;
      position :absolute;
      width: 100%;
      -moz-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      top: 50%;
    }
  }
}