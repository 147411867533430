.l-homeplayer-detail {
  border-top: 5px solid $hama_blue_2;
  padding: 60px 0 0 0 ;
  &__name {
    padding: 10px 0;
  }
  &__profile {
    padding:30px 0;
    @media (min-width: 581px) {
      padding: 60px 0;
    }
  }
  &__img-slide {
    padding:34px 0 0 0;
  }
}