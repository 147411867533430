$hama_black_1: #403D3C;
$hama_black_2: #251E1C;
$hama_black_3: #000000;
$hama_black_4: #333333;
$hama_black_5: #666666;
$hama_black_6: #1A1A1A;

$hama_gray_1: #A6A6A7;
$hama_gray_2: #E5E5E5;
$hama_gray_3: #CDCDCD;
$hama_gray_4: #B2B2B2;
$hama_gray_5: #9A9A9B;
$hama_gray_6: #AEAEAE;
$hama_gray_7: #8F8F8F;
$hama_gray_8: #C7C7C7;
$hama_gray_9: #dbdcdc;
$hama_gray_10: #8c8c8c;

$hama_white_1: #FFFFFF;
$hama_white_2: #EAF4FF;
$hama_white_3: #F2F2F2;

$hama_blue_1: #325AA0;
$hama_blue_2: #2F96FF;
$hama_blue_3: #56A6F6;
$hama_blue_4: #A8C3FF;
$hama_blue_5: #1DD0CC;
$hama_blue_6: #82C0FF;
$hama_blue_7: #98accf;

$hama_red_1: #DC0000;
$hama_red_2: #FF0000;
$hama_red_3: #FF6666;
$hama_red_4: #FBE5E5;

$hama_green_1: #008585;
$hama_green_2: #59B53D;
$hama_green_3: #1DD06E;
$hama_green_4: #008A8A;
$hama_green_5: #E5F3F3;
$hama_green_6: #008282;

$hama_pink_1: #FF4C96;

$hama_orange_1: #FF692B;
$hama_orange_2: #FFF0EA;
$hama_orange_3: #FFB495;

$hama_yellow_1: #FFF6CE;
$hama_yellow_2: #ffff66;
$hama_yellow_3: #ffde6a;