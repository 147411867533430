.p-hamburger-btn {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  top: 10px;
  left: 10px;
  z-index: 10100;
  background-color: $hama_blue_2;
  border-radius: 8px;
  > .is-border {
    position: absolute;
    display: inline-block;
    width: 22px;
    height: 3px;
    background-color: $hama_white_1;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    top: 13.5px;
    transition: .3s;
    &.is-active {
      opacity: 0;
    }
    &.is-first{
      top: 5.5px;
      opacity: 1;
      &.is-active {
        top: 13.5px;
        transform: translateX(-50%) rotate(-45deg);
        -webkit-transform: translateX(-50%) rotate(-45deg);
        -ms-transform: translateX(-50%) rotate(-45deg);
      }
    }
    &.is-last{
      top: 21.5px;
      opacity: 1;
      &.is-active {
        top: 13.5px;
        transform: translateX(-50%) rotate(45deg);
        -webkit-transform: translateX(-50%) rotate(45deg);
        -ms-transform: translateX(-50%) rotate(45deg);
      }
    }
  }
  > .is-text {
    position: absolute;
    display: none;
    &.is-active {
      display: inline-block;
      width: 100%;
      top: 28px;
      left: 0;
      color: $hama_white_1;
      font-size:7.5px;
      line-height: 7.5px;
      font-weight: 600;
      text-align: center;
    }
  }
}