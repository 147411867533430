.u-col-full {
  flex: 0 0 auto;
  width: 100%;
}

.u-col-half-sp-10 {
  flex: 0 0 auto;
  //width: calc(50% - (10px / 2));
  width: 50%;
  padding: 0 5px;
}

.u-col-84 {
  flex: 0 0 auto;
  width: 84px;
}

@media (max-width: 580px) {
  .u-col-sm-no-margin {
    margin: 0 -10px;
    width: calc(100% + 20px);
  }
}

@media (min-width: 581px) {
  .u-col-md-quarter-sp-20 {
    flex: 0 0 auto;
    //width: calc(25% - ((20px * 3) / 4));
    width: 25%;
    padding: 0 10px;
  }

  .u-col-md-half {
    flex: 0 0 auto;
    width: 50%;
  }

  .u-col-md-70 {
    flex: 0 0 auto;
    width: 70px;
  }

  .u-col-md-220 {
    flex: 0 0 auto;
    width: 220px;
  }

  .u-col-md-236 {
    flex: 0 0 auto;
    width: 236px;
  }

  .u-col-md-270 {
    flex: 0 0 auto;
    width: 270px;
  }

  .u-col-md-278 {
    flex: 0 0 auto;
    width: 278px;
  }

  .u-col-md-283 {
    flex: 0 0 auto;
    width: 283px;
  }

  .u-col-md-285 {
    flex: 0 0 auto;
    width: 285px;
  }

  .u-col-md-304 {
    flex: 0 0 auto;
    width: 304px;
  }

  .u-col-md-330 {
    flex: 0 0 auto;
    width: 330px;
  }

  .u-col-md-384 {
    flex: 0 0 auto;
    width: 384px;
  }

  .u-col-md-400 {
    flex: 0 0 auto;
    width: 400px;
  }
  .u-col-md-495 {
    flex: 0 0 auto;
    width: 495px;
  }
  .u-col-md-530 {
    flex: 0 0 auto;
    width: 530px;
  }
  .u-col-md-580 {
    flex: 0 0 auto;
    width: 580px;
  }
  .u-col-md-588 {
    flex: 0 0 auto;
    width: 588px;
  }
  .u-col-md-590 {
    flex: 0 0 auto;
    width: 590px;
  }
  .u-col-md-634 {
    flex: 0 0 auto;
    width: 634px;
  }
  .u-col-md-660 {
    flex: 0 0 auto;
    width: 660px;
  }
  .u-col-md-740 {
    flex: 0 0 auto;
    width: 740px;
  }
  .u-col-md-780 {
    flex: 0 0 auto;
    width: 780px;
  }
  .u-col-md-860 {
    flex: 0 0 auto;
    width: 860px;
  }
  .u-col-md-890 {
    flex: 0 0 auto;
    width: 890px;
  }
  .u-col-md-956 {
    flex: 0 0 auto;
    width: 956px;
  }
  .u-col-md-1020 {
    flex: 0 0 auto;
    width: 1020px;
  }
  .u-col-md-1050 {
    flex: 0 0 auto;
    width: 1050px;
  }
}

.u-col-info-nav {
  flex: 0 0 auto;
  width: calc((100% - (16px)) / 2);
  @media (min-width: 581px) {
    width: calc((100% - (8px * 7)) / 8);
  }
}