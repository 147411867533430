.c-link-1 {
  display: inline-block;
  text-decoration: none;
  position: relative;
  color: inherit;
  &::after {
    content: "";
    display: inline-block;
    width: 20px;
    height:20px;
    vertical-align: middle;
    background-image: url("../images/common/link_marker.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 0 0 0.5rem;
  }
}