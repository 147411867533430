.u-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 10px;
  @media (min-width: 581px) {
    padding: 0 20px;
  }
  &.is-center {
    justify-content: center;
  }
  &.is-start {
    justify-content: start;
  }
  &.is-sp-10 {
    margin: 0 -5px;
  }
  &.is-md-sp-20 {
    @media (min-width: 581px) {
      margin: 0 -10px;
    }
  }
}
.u-row__homeplayer-detail__profile {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 10px;
  @media (min-width: 581px) {
    padding: 0 60px;
  }
}

.u-row-sm-no-padding {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  @media (min-width: 581px) {
    padding: 0 20px;
  }
}

.u-row-no-padding {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
}

.u-row-sm-p-31 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 31px;
  @media (min-width: 581px) {
    padding: 0 20px;
  }
}
