.p-ttl-1 {
  font-size: 1.8125rem;
  line-height: 2.375rem;
  color: $hama_blue_1;
  font-weight: 600;
  text-align: center;
  > img {
    display: inline-block;
    width: 2.375rem;
    height: 2.375rem;
    margin-right: 0.875rem;
    vertical-align: bottom;
  }
}

.p-ttl-2 {
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: $hama_blue_1;
  font-weight: 600;
  text-align: center;
  @media (min-width: 581px) {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}

.p-ttl-3 {
  font-size: 1.25rem;
  line-height: 1.375rem;
  padding: 9px;
  font-weight: 600;
  background-color: $hama_white_3;
  border-left: 10px solid $hama_blue_2;
  @media (min-width: 581px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
    padding: 11px;
  }
  &.is-red {
    border-left: 10px solid $hama_red_2;
  }
}

.p-ttl-4 {
  display: inline-block;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: $hama_white_1;
  background-color: $hama_blue_1;
  width: 204px;
  padding: 5px 0;
  text-align: center;
  border-radius: 16px;
  @media (min-width: 581px) {
    font-size: 22px;
    line-height: 28px;
    width: 255px;
    padding: 6px 0;
    border-radius: 20px;
  }
}

.p-ttl-5 {
  font-size: 1.0625rem;
  line-height: 1.0625rem;
  color: $hama_black_6;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid $hama_blue_2;
  @media (min-width: 581px) {
    font-size: 1.375rem;
    line-height: 1.375rem;
  }
  &.is-red {
    border-bottom: 1px solid $hama_red_2;
  }
}

.p-ttl-6 {
  position: relative;
  display: block;
  font-size: 19px;
  line-height: 23px;
  font-weight: 600;
  color: $hama_white_1;
  background-color: $hama_blue_1;
  width: 100%;
  height: 60px;
  padding: 5px 0;
  text-align: center;
  border-radius: 30px;
  margin: 0 auto;
  @media (min-width: 581px) {
    font-size: 28px;
    line-height: 32px;
    width: 634px;
    padding: 6px 0;
    border-radius: 30px;
  }
  > span {
    display: block;
    position :absolute;
    width: 100%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
  }
}

.p-ttl-7 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: $hama_blue_1;
  font-weight: 600;
  position: relative;
  padding-left: calc(1.75rem + 5px);
  @media (min-width: 581px) {
    font-size: 1.875rem;
    line-height: 2.375rem;
    padding-left: calc(2.375rem + 5px);
  }
  &::before {
    content:"";
    display: block;
    position:absolute;
    top:0;
    left:0;
    width: 1.75rem;
    height: 1.75rem;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    @media (min-width: 581px) {
      width: 2.375rem;
      height: 2.375rem;
    }
  }
  &.is-1 {
    &::before {
      background-image: url("../images/common/head_num_1.png");
    }
  }
  &.is-2 {
    &::before {
      background-image: url("../images/common/head_num_2.png");
    }
  }
}

.p-ttl-8 {
  font-size: 1.0625rem;
  line-height: 1.0625rem;
  color: $hama_red_2;
  font-weight: 600;
  padding: 21px 0;
  border: 3px solid $hama_red_2;
  background-color: $hama_white_1;
  text-align: center;
  @media (min-width: 581px) {
    font-size: 1.375rem;
    line-height: 1.375rem;
    padding: 26px 0;
  }
}