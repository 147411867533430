.p-pagination-1 {
  display: block;
  &__container {
    text-align: center;
    >a,span {
      display: inline-block;
      width: 30px;
      height: 30px;
      text-align: center;
      text-decoration: none;
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
      &:not(:last-child){
        margin-right: 10px;
      }
      &.is-number {
        border: 1px solid $hama_blue_1;
        border-radius: 4px;
        background-color: $hama_white_1;
        color: $hama_black_1;
      }
      &.is-current {
        background-color: $hama_blue_1;
        color: $hama_white_1;
      }
      &.is-prev {
        margin-right: 20px;
        > img {
          margin-top: -2px;
        }
      }
      &.is-next {
        margin-left: 10px;
        > img {
          margin-top: -2px;
        }
      }
      &.is-prev-space {
        margin-right: 20px;
      }
      &.is-next-space {
        margin-left: 10px;
      }
    }
  }
}
.p-pagination-2 {
  display: block;
  &__container {
    text-align: center;
    >a,span {
      display: inline-block;
      font-size: 20px;
      line-height: 20px;
      font-weight: 600;
      &.is-archive {
        color: $hama_white_1;
        background-color: $hama_blue_1;
        padding: 5px 8px;
        border-radius: 4px;
        text-decoration: none;
        width: 136px;
      }
      &.is-prev {
        width: 30px;
        height: 30px;
        text-align: center;
        text-decoration: none;
        margin-right: 60px;
        @media (min-width: 581px) {
          margin-right: 120px;
        }
        > img {
          margin-top: -2px;
        }
      }
      &.is-next {
        width: 30px;
        height: 30px;
        text-align: center;
        text-decoration: none;
        margin-left: 60px;
        @media (min-width: 581px) {
          margin-left: 120px;
        }
        > img {
          margin-top: -2px;
        }
      }
      &.is-prev-space {
        width: 30px;
        height: 30px;
        text-align: center;
        text-decoration: none;
        margin-right: 60px;
        @media (min-width: 581px) {
          margin-right: 120px;
        }
      }
      &.is-next-space {
        width: 30px;
        height: 30px;
        text-align: center;
        text-decoration: none;
        margin-left: 60px;
        @media (min-width: 581px) {
          margin-left: 120px;
        }
      }
    }
  }
}