.p-front-info-box {
  background-color: $hama_red_1;
  line-height: 1.5rem;
  @media (min-width: 581px) {
    line-height: 2rem;
  }
  &__text {
    display: flex;
    padding: 7px 0;
    @media (min-width: 581px) {
      padding: 9px 0;
    }
  }
  &__plate {
    display: inline-block;
    white-space: nowrap;
    font-size:0.875rem;
    font-weight: 700;
    background-color: $hama_white_1;
    border-radius: 8px;
    padding: 0 0.5rem;
    color: $hama_black_2;
    margin: 0 0.75rem 0 0;
    @media (min-width: 581px) {
      font-size:1rem;
      padding: 0 1rem;
      margin: 0 1rem 0 0;
    }
  }
  &__link-wrap{
    display: inline-block;
    width: 100%;
    overflow: hidden;
    > span{
      display: block;
      white-space : nowrap;
      width: fit-content;
      //transform: translateX(100%);
      //animation: marquee 23s linear infinite;

      > a {
        display: inline-block;
        white-space: nowrap;
        font-size:0.8125rem;
        color: $hama_white_1;
        line-height: 1.5rem;


        &:not(:last-child){
          margin-right: 355px;
          @media (min-width: 581px) {
            margin-right: 1200px;
          }
        }
        @media (min-width: 581px) {
          font-size:0.875rem;
          line-height: 2rem;
        }
      }
    }
  }
  &__sns-link {
    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > li {
        display: block;
        flex: 0 0 auto;
        width: 25%;
        > a {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          height: 100%;
          padding: 15px 0;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
          > figure {
            margin: 0 0 15px 0;
            > img {
              display: inline-block;
              width: 52px;
              height: 52px;
            }
          }
          > div {
            text-align: center;
            font-size: 8px;
            line-height: 11px;;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@keyframes marquee {
  0% {
    -moz-transform: translateX(100vw);
    -webkit-transform: translateX(100vw);
    transform: translateX(100vw);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}