.p-table-1 {
  width: 100%;
  tr {
    border-bottom: 4px solid transparent;
    th,td {
      text-align: left;
      font-size: 0.750rem;
      line-height: 1.375rem;
      height: 33px;
      font-weight: 400;
      border: none;
      vertical-align: middle;
      padding: 16px;
      @media (min-width: 581px) {
        font-size: 0.875rem;
        line-height: 1.375rem;
        height: 53px;
      }
    }
    th {
      background-color: $hama_blue_1;
      color: $hama_white_1;
      width: 128px;
      @media (min-width: 581px) {
        width: 188px;
      }
    }
    td {
      background-color: $hama_white_1;
      color: $hama_black_1;
    }
  }
}

.p-table-2 {
  width: 100%;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid $hama_gray_4;
    &:first-child{
      border-top: 1px solid $hama_gray_4;
    }

    th,td {
      text-align: left;
      font-size: 0.750rem;
      line-height: 1.375rem;
      font-weight: 400;
      border: none;
      vertical-align: middle;
      padding: 16px;
      color: $hama_black_1;
      @media (min-width: 581px) {
        font-size: 0.875rem;
        line-height: 1.375rem;
        height: 53px;
      }
    }
    th {
      background-color: $hama_white_3;
      width: 128px;
      border-right: 1px solid $hama_gray_4;
      @media (min-width: 581px) {
        width: 460px;
      }
    }
    td {
      background-color: $hama_white_1;
    }
  }
}

.p-table-3 {
  width: 100%;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid $hama_gray_4;
    &:first-child{
      border-top: 1px solid $hama_gray_4;
    }
    th,td {
      text-align: left;
      font-size: 0.750rem;
      line-height: 1.375rem;
      font-weight: 400;
      border: none;
      vertical-align: middle;
      padding: 14px 4px;
      color: $hama_black_1;
      @media (min-width: 581px) {
        padding: 16px;
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
      &.w44{
        width: 44px;
      }
      &.w50{
        width: 50px;
      }
      &.w60{
        width: 60px;
      }
      &.w81{
        width: 81px;
      }
      &.w116{
        width: 116px;
      }
      &.w104{
        width: 104px;
      }
      &.wmd144{
        @media (min-width: 581px) {
          width: 144px;
        }
      }
      &.wmd164{
        @media (min-width: 581px) {
          width: 164px;
        }
      }
      &.wmd311{
        @media (min-width: 581px) {
          width: 311px;
        }
      }
      &.wmd578{
        @media (min-width: 581px) {
          width: 578px;
        }
      }
      &.is-border-right{
        border-right: 1px solid $hama_gray_4;
      }
    }
    th {
      background-color: $hama_white_3;
      &:not(:last-child){
        border-right: 1px solid $hama_gray_4;
      }
    }
    td {
      background-color: $hama_white_1;
      &:not(:last-child){
        border-right: 1px solid $hama_gray_4;
      }
    }
  }
}

.p-table-4 {
  width: 100%;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid $hama_gray_4;
    &:first-child{
      border-top: 1px solid $hama_gray_4;
    }
    th,td {
      text-align: center;
      font-size: 0.750rem;
      line-height: 1.375rem;
      font-weight: 400;
      border: none;
      vertical-align: middle;
      padding: 16px;
      color: $hama_black_1;
      @media (min-width: 581px) {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
      &.bgw3{
        background-color: $hama_white_3;;
      }
      &.w50{
        width: 50px;
      }
      &.w140{
        width: 140px;
      }
      &.wmd80{
        @media (min-width: 581px) {
          width: 80px;
        }
      }
      &.wmd300{
        @media (min-width: 581px) {
          width: 300px;
        }
      }
    }
    td {
      background-color: $hama_white_1;
      &:not(:last-child){
        border-right: 1px solid $hama_gray_4;
      }
    }
  }
}

.p-table-5 {
  width: 100%;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid $hama_gray_4;
    th,td {
      font-size: 0.750rem;
      line-height: 1.375rem;
      font-weight: 400;
      border: none;
      vertical-align: middle;
      padding: 16px;
      color: $hama_black_1;
      @media (min-width: 581px) {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
      &.w140{
        width: 140px;
      }
    }
    td {
      background-color: $hama_white_1;
      &:not(:last-child){
        border-right: 1px solid $hama_gray_4;
      }
    }
  }
}