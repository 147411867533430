.p-btn-1 {
  position: relative;
  display: inline-block;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  height: 50px;
  padding: 14px 20px;
  background-color: $hama_blue_1;
  color: $hama_white_1;
  text-decoration: none;
  &::before {
    content:"";
    display: block;
    position: absolute;
    top: 0;
    left: -30px;
    width: 60px;
    height: 100%;
    background-color: $hama_blue_1;
    clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0 100%);
    z-index: 0;
  }
  &::after {
    content:"";
    display: block;
    position: absolute;
    top: 0;
    right: -30px;
    width: 60px;
    height: 100%;
    background-color: $hama_blue_1;
    clip-path: polygon(0 0, 100% 0, 50% 100%, 0 100%);
    z-index: 0;
  }
  > span {
    z-index: 10;
    position: relative;
  }
}

.p-btn-2 {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color:$hama_blue_1;
  background-color: $hama_white_1;
  border: 2px solid $hama_blue_1;
  border-radius: 8px;
  text-align: center;
  width:96px;
  height: 24px;
  cursor: pointer;
  @media (min-width: 581px) {
    font-size: 20px;
    line-height: 30px;
    width:120px;
    height: 30px;
  }
  &:hover {
    opacity: .5;
  }
  &.is-current {
    color:$hama_white_1;
    background-color: $hama_blue_1;
  }
}

.p-btn-3 {
  display: block;
  position:relative;
  background-color: $hama_blue_1;
  border: 4px solid $hama_blue_7;
  border-radius: 16px;
  width: 100%;
  text-decoration: none;
  color:$hama_white_1;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  //padding: 25px 0;
  height: 72px;
  @media (min-width: 581px) {
    font-size: 20px;
    line-height: 26px;
    //padding: 27px 0;
    height: 80px;
  }
  &::after {
    content: "";
    display: block;
    position :absolute;
    width: 15px;
    height:26px;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    right: 8px;
    background-image: url("../images/common/btn_marker.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  > span {
    display: block;
    position :absolute;
    width: 100%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
  }
}

.p-btn-4 {
  display: block;
  position:relative;
  background-color: $hama_orange_1;
  border: 4px solid $hama_orange_3;
  border-radius: 16px;
  width: 100%;
  text-decoration: none;
  color:$hama_white_1;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  height: 72px;
  @media (min-width: 581px) {
    font-size: 28px;
    line-height: 28px;
    height: 80px;
  }
  &::after {
    content: "";
    display: block;
    position :absolute;
    width: 15px;
    height:26px;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    right: 8px;
    background-image: url("../images/common/btn_marker.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.is-disable {
    background-color: $hama_gray_7;
    border: 4px solid $hama_gray_8;
  }
  > span {
    display: block;
    position :absolute;
    width: 100%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
  }
}

.p-btn-acrobat-reader {
  display: inline-block;
  width: 330px;
  height: 30px;
}