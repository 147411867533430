.l-contact {
  border-top: 5px solid $hama_blue_2;
  padding: 60px 0 0 0;
  &__form-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    >.is-required {
      flex: 0 0 auto;
      width: 52px;
      background-color: $hama_gray_5;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 581px) {
        width: 60px;
        padding: 20px 10px;
      }
      > span {
        display: inline-block;
        font-size: 11px;
        line-height: 16px;
        font-weight: 600;
        width: 32px;
        height: 16px;
        background-color: $hama_red_2;
        color: $hama_white_1;
        text-align: center;
        @media (min-width: 581px) {
          font-size: 14px;
          line-height: 20px;
          width: 40px;
          height: 20px;
        }
      }
    }
    >.is-head {
      flex: 0 0 auto;
      width: calc(100% - 52px);
      background-color: $hama_yellow_1;
      display: flex;
      align-items: center;
      justify-content: start;
      padding:6.5px;
      @media (min-width: 581px) {
        width: 230px;
        padding: 20px;
        padding: 30px 20px;
      }
      span,label {
        font-size: 17px;
        line-height: 17px;
        font-weight: 600;
        color: $hama_black_1;
        @media (min-width: 581px) {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
    >.is-input {
      flex: 0 0 auto;
      width: 100%;
      background-color: $hama_white_3;
      padding: 10px;
      @media (min-width: 581px) {
        width: calc(100% - 60px - 230px);
        padding: 20px;
      }
      >.is-errmsg{
        margin: 10px 0 0 0;
        color: $hama_red_2;
        font-size: 0.750rem;
        line-height: 1.25rem;
        color: $hama_red_2;
        @media (min-width: 581px) {
          font-size: 0.875rem;
          line-height: 1.375rem;
        }
      }
    }
    &__input-text-xs {
      background-color: $hama_white_1;
      border: 1px solid $hama_gray_6;
      border-radius: 4px;
      font-size: 16px;
      line-height: 28px;
      padding: 5px;
      width: 80px;
      display: inline-block;
      margin: 0 10px 0 0;
      @media (min-width: 581px) {
        display: inline-block;
      }
      &::placeholder {
        font-size: 16px;
        line-height: 28px;
        color: $hama_gray_6;
      }
    }
    &__input-text-s {
      background-color: $hama_white_1;
      border: 1px solid $hama_gray_6;
      border-radius: 4px;
      font-size: 16px;
      line-height: 28px;
      padding: 5px;
      width: 224px;
      display: block;
      @media (min-width: 581px) {
        display: inline-block;
        margin: 0 10px 0 0;
      }
      &::placeholder {
        font-size: 16px;
        line-height: 28px;
        color: $hama_gray_6;
      }
    }
    &__input-text-326 {
      background-color: $hama_white_1;
      border: 1px solid $hama_gray_6;
      border-radius: 4px;
      font-size: 16px;
      line-height: 28px;
      padding: 5px;
      width: 326px;
      display: block;
      &::placeholder {
        font-size: 16px;
        line-height: 28px;
        color: $hama_gray_6;
      }
      @media (min-width: 581px) {
        display: inline-block;
      }
    }
    &__input-text-ml {
      background-color: $hama_white_1;
      border: 1px solid $hama_gray_6;
      border-radius: 4px;
      font-size: 16px;
      line-height: 28px;
      padding: 5px;
      width: 100%;
      max-width: 574px;
      display: block;
      &::placeholder {
        font-size: 16px;
        line-height: 28px;
        color: $hama_gray_6;
      }
    }
    &__input-text-l {
      background-color: $hama_white_1;
      border: 1px solid $hama_gray_6;
      border-radius: 4px;
      font-size: 16px;
      line-height: 28px;
      padding: 5px;
      width: 100%;
      display: block;
      &::placeholder {
        font-size: 16px;
        line-height: 28px;
        color: $hama_gray_6;
      }
    }
    &__input-text-y {
      background-color: $hama_white_1;
      border: 1px solid $hama_gray_6;
      border-radius: 4px;
      font-size: 16px;
      line-height: 28px;
      padding: 5px;
      width: 100px;
      display: inline-block;
      margin: 0 10px 0 0;
      margin-left: 10px;
      &.is-first {
        width: 80px;
        margin-left: 0px;
      }
    }
    &__input-text-t {
      background-color: $hama_white_1;
      border: 1px solid $hama_gray_6;
      border-radius: 4px;
      font-size: 16px;
      line-height: 28px;
      padding: 5px;
      width: 80px;
      display: inline-block;
      display: inline-block;
      margin: 0 10px 0 0;
      margin-left: 10px;
      &.is-first {
        margin-left: 0px;
      }
    }
    &__textarea {
      background-color: $hama_white_1;
      border: 1px solid $hama_gray_6;
      border-radius: 4px;
      font-size: 16px;
      line-height: 28px;
      padding: 5px;
      width: 100%;
      display: block;
      height: 240px;
      &::placeholder {
        font-size: 16px;
        line-height: 28px;
        color: $hama_gray_6;
        height: 140px;
      }
    }
    &__submit {
      display: block;
      width: 100%;
      height: 52px;
      background-image: url("../images/contact/form_btn_sm.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      text-indent: -9999px;
      cursor: pointer;
      border: none;
      transition: .3s;
      &:hover {
        opacity: .5;
      }
      @media (min-width: 581px) {
        background-image: url("../images/contact/form_btn_md.png");
        width: 540px;
        height: 60px;
      }
      &.is-back {
        background-image: url("../images/contact/form_btn_back_sm.png");
        @media (min-width: 581px) {
          background-image: url("../images/contact/form_btn_back_md.png");
          width: 250px;
        }
      }
      &.is-enter {
        background-image: url("../images/contact/form_btn_enter_sm.png");
        @media (min-width: 581px) {
          background-image: url("../images/contact/form_btn_enter_md.png");
          width: 250px;
        }
      }
      &-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    &__select {
      background-color: $hama_white_1;
      border: 1px solid $hama_gray_6;
      border-radius: 4px;
      font-size: 16px;
      line-height: 28px;
      padding: 8.5px;
      width: 80px;
      display: inline-block;
      display: inline-block;
      margin: 0 10px 0 0;
      margin-right: 10px;
    }
    &__radio {
      > input[type=radio] {
        display: none;
        + label{
          position: relative;
          display: inline-block;
          padding-left: 36px;
          &::before{
            display: inline-block;
            position: absolute;
            content: "";
            width: 24px;
            height: 24px;
            left:0;
            border: 1px solid $hama_gray_6;
            border-radius: 4px;
            background-color: $hama_white_1;
          }
        }
        &:checked{
          + label {
            &::after{
              display: inline-block;
              position: absolute;
              content: "";
              width: 14px;
              height:7px;
              border-bottom: 3px solid;
              border-left: 3px solid;
              border-color:  $hama_gray_6;
              transform: rotate(-45deg);
              top: 50%;
              left: 4px;
              margin-top: -5px;
            }
          }
        }
      }
    }
    &__check-box {
      > input[type=checkbox] {
        display: none;
        + label{
          position: relative;
          display: inline-block;
          padding-left: 36px;
          &::before{
            display: inline-block;
            position: absolute;
            content: "";
            width: 24px;
            height: 24px;
            left:0;
            border: 1px solid $hama_gray_6;
            border-radius: 4px;
            background-color: $hama_white_1;
          }
          &.is-race {
            width: 4rem;
            margin-right: 10px;
          }
        }
        &:checked{
          + label {
            &::after{
              display: inline-block;
              position: absolute;
              content: "";
              width: 14px;
              height:7px;
              border-bottom: 3px solid;
              border-left: 3px solid;
              border-color:  $hama_gray_6;
              transform: rotate(-45deg);
              top: 50%;
              left: 4px;
              margin-top: -5px;
            }
          }
        }
        &:disabled{
          + label {
            &::before{
              background-color: $hama_white_3;
            }
          }
        }
      }
    }
    &__unit {
      margin-right: 10px;
      vertical-align: bottom;
    }
  }
}