.u-bg__blue-2 {
  background-color: $hama_blue_2;
}

.u-bg__white-1 {
  background-color: $hama_white_1;
}

.u-bg__white-3 {
  background-color: $hama_white_3;
}

.u-bg__black-3 {
  background-color: $hama_black_3;
}

.u-bg__gray_2 {
  background-color: $hama_gray_2;
}

.u-bg__gray_9 {
  background-color: $hama_gray_9;
}

.u-bg__green-2 {
  background-color: $hama_green_2;
}

.u-bg__blue-2 {
  background-color: $hama_blue_2;
}

.u-bg__yellow-3 {
  background-color: $hama_yellow_3;
}