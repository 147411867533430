.u-d-none {
  display: none;
}
.u-d-inline {
  display: inline;
}
.u-d-block {
  display: block;
}
.u-d-inline-block {
  display: inline-block;
}
@media (min-width: 581px) {
  .u-d-md-none {
    display: none;
  }
  .u-d-md-inline {
    display: inline;
  }
  .u-d-md-block {
    display: block;
  }
  .u-d-md-inline-block {
    display: inline-block;
  }
}