.c-text-footer-1 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: $hama_white_1;
}

.c-text-footer-2 {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  color: $hama_white_1;
  @media (min-width: 581px) {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 600;
  }
}

.c-text-1 {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 600;
  color: $hama_black_1;
}

.c-text-2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 600;
  color: $hama_blue_1;
}

.c-text-3 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: $hama_blue_1;
}

.c-text-4 {
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: $hama_black_1;
}

.c-text-5 {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 600;
  color: $hama_blue_1;
  @media (min-width: 581px) {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}

.c-text-6 {
  font-size: 0.750rem;
  line-height: 1.25rem;
  color: $hama_black_1;
  @media (min-width: 581px) {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
}

.c-text-7 {
  font-size: 1.375rem;
  line-height: 1.625rem;
  font-weight: 600;
  color: $hama_blue_1;
  @media (min-width: 581px) {
    font-size: 1.875rem;
    line-height: 2.375rem;
  }
}

.c-text-8 {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  @media (min-width: 581px) {
    font-size: 1.375rem;
    line-height: 1.375rem;
  }
}