.u-container {
  position: relative;
  width: 100%;
  @media (min-width: 581px) {
    width: 1240px;
    margin: 0 auto;
  }
  &.is-hidden {
    overflow: hidden;
  }
}

.u-container-full {
  position: relative;
  width: 100%;
  overflow: hidden;
}