.l-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  padding: 10px 0 10px;
  height: 65px;
  border-bottom: 5px solid $hama_blue_2;
  background-color: $hama_white_1;
  font-size: 16px;
  overflow: hidden;
  @media (min-width: 581px) {
    height: 88px;
    border: none;
  }
  &__container{
    position: relative;
    overflow: unset !important;
    &::before {
      content:"";
      display: block;
      width: 68px;
      height: 60px;
      background-color: $hama_blue_2;
      clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0 100%);
      position: absolute;
      top: -10px;
      right: -34px;
      @media (min-width: 581px) {
        width: 100px;
        height: 88px;
        top: -10px;
        right: unset;
        left:410px;
      }
    }
    &::after {
      display: none;
      @media (min-width: 581px) {
        content:"";
        display: block;
        width: 780px;
        height: 88px;
        position: absolute;
        top: -10px;
        right: 0;
        background: linear-gradient(90deg, $hama_blue_2, $hama_white_1);
      }
    }
  }
  &__logotype {
    height: 40px;
    text-align: center;
    @media (min-width: 581px) {
      display: inline-block;
      height: 66px;
      text-align: left;
    }
    > a {
      display: inline-block;
      width: auto;
      height: 40px;
      @media (min-width: 581px) {
        max-width: 350px;
        height: 66px;
      }
      > img {
        vertical-align: top;
        width: auto;
        height: 40px;
        @media (min-width: 581px) {
          max-width: 350px;
          height: 66px;
        }
      }
    }
  }
  &__utility-box{
    display: none;
    @media (min-width: 581px) {
      display: flex;
      flex-wrap: wrap;
      position:absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      z-index: 10100;
    }
  }
  &__sns-link{
    margin: 0 40px 0 0;
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      > li {
        display: inline-block;
        width: 40px;
        height: 40px;
        &:not(:last-child){
          margin: 0 10px 0 0;
        }
        > a {
          display: inline-block;
          &:hover {
            
          }
        }
      }
    }
  }
  &__text-size-btn{
    display: block;
    border-radius: 7px;
    background-color: $hama_blue_2;
    padding: 7px;
    height: 40px;
    font-size: 0;
    > p {
      display: inline-block;
      font-size: 14px;
      line-height: 26px;
      font-weight: 600;
      color: $hama_white_1;
      margin: 0 7px 0 0;
      vertical-align: middle;
    }
    > button {
      display: inline-block;
      width: 66px;
      height: 26px;
      color: $hama_blue_1;
      font-weight: 600;
      text-align: center;
      border: none;
      border-radius: 7px;
      outline: none;
      background-color: $hama_white_1;
      vertical-align: middle;
      cursor: pointer;
      &:nth-of-type(1) {
        font-size: 16px;
        margin: 0 7px 0 0;
      }
      &:nth-of-type(2){
        font-size: 18px;
        margin: 0 7px 0 0;
      }
      &:nth-of-type(3) {
        font-size: 20px;
        margin: 0;
      }
      &:hover,&.is-active{
        background-color: $hama_blue_4;
      }
    }
  }
}
