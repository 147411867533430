.p-announcement-slider {
  list-style: none;
  margin: 0;
  padding: 0;
  .slick-slide{
    position: relative;
    overflow: hidden;
    width: 100vw;
    max-width: 580px;
    aspect-ratio: 780 / 483;
    @media (min-width: 581px) {
      width: 780px;
      max-width: 780px;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__dots-nav {
    .slick-dots {
      text-align: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
      }
      button {
        display: block;
        width: 12px;
        height: 12px;
        margin: 12px;
        font-size: 0;
        padding: 0;
        border: 0;
        outline: none;
        cursor: pointer;
        background: $hama_gray_1;
        border-radius: 50%;
      }
      .slick-active button {
        background: $hama_blue_2;
      }
    }
  }
}

.p-rotation-bnr-slider {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  .slick-list {
    padding: 0 !important;
    line-height: 0;
  }
  .slick-slide{
    position: relative;
    overflow: hidden;
    width: 100vw;
    max-width: 580px;
    aspect-ratio: 1240 / 514;
    @media (min-width: 581px) {
      /*
      width: 1240px;
      max-width: 1240px;
      */
      
      width: 100%;
      min-width: 1240px;
      max-width: 100vw;
      
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .slide-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    width: 32px;
    height: 32px;
    @media (min-width: 581px) {
      width: 40px;
      height: 40px;
    }
  }
  .prev-arrow {
    left: 10px;
    @media (min-width: 581px) {
      left: 20px;
    }
  }
  .next-arrow {
    right: 10px;
    @media (min-width: 581px) {
      right: 20px;
    }
  }
}

.p-homeplayer-detail-slider {
  list-style: none;
  margin: 0;
  padding: 0;
  .slick-slide{
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 355px;
    aspect-ratio: 740 / 420;
    @media (min-width: 581px) {
      width: 740px;
      max-width: 740px;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__dots-nav {
    .slick-dots {
      text-align: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
      }
      button {
        display: block;
        width: 12px;
        height: 12px;
        margin: 12px;
        font-size: 0;
        padding: 0;
        border: 0;
        outline: none;
        cursor: pointer;
        background: $hama_gray_1;
        border-radius: 50%;
      }
      .slick-active button {
        background: $hama_blue_2;
      }
    }
  }
}