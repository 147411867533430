.l-flag {
  border-top: 5px solid $hama_blue_2;
  padding: 60px 0 0 0;
  &__form-card {
    background-color: $hama_white_3;
    padding: 10px;
    word-break: break-all;
    word-wrap: anywhere;
    &__ttl {
      display: block;
      padding: 10px;
      color: $hama_white_1;
      background-color: $hama_red_2;
      font-size: 1.0625rem;
      line-height: 1.25rem;
      font-weight: 600;
      text-align: center;
      @media (min-width: 581px) {
        font-size: 1.75rem;
        line-height: 2rem;
      }
    }
    &__date {
      display: block;
      padding: 8px;
      color: $hama_white_1;
      background-color: $hama_black_2;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      font-weight: 600;
      text-align: center;
      @media (min-width: 581px) {
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }
    &__detail {
      display: block;
      padding: 8px;
      color: $hama_black_1;
      background-color: $hama_white_1;
      font-size: 0.8125rem;
      line-height: 1.25rem;
      text-align: left;
      @media (min-width: 581px) {
        font-size: 1.25rem;
        line-height: 1.875rem;
        text-align: center;
      }
      > span {
        display: block;
        @media (min-width: 581px) {
          display: inline-block;
          margin-right: 2.5rem;
        }
        >.is-blue-1 {
          color: $hama_blue_1;
        }
        >.is-blue-2 {
          color: $hama_blue_2;
        }
      }
    }
    &__btn {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 20px 20px 20px 10px;
      > a {
        display: block;
        width: 226px;
        @media (min-width: 581px) {
          width: 282px;
        }
        > img {
          display: block;
        }
      }
    }
  }
}